import logo from './logo.svg';
import brackets from './brackets.svg';

import './App.scss';
import ARSceneComponent from './8thwall/ARSceneComponent';

function App() {
  return (
    <div className="App">
      <ARSceneComponent></ARSceneComponent>
      <div className='link'>
        <a href="http://businesscards.craftworkz.be/" target="_blank">
          <svg version="1.1" x="0px" y="0px" viewBox="0 0 350 350">
            <g id="CIRCLE">
              <circle id="XMLID_22_" cx="175" cy="175" r="170"/>
            </g>
            <g id="_x30_5">
              <g id="XMLID_8_">
                <line id="XMLID_122_" className="st0" x1="164" y1="81.5" x2="208.9" y2="86.8"/>
                <line id="XMLID_123_" className="st0" x1="208.9" y1="86.8" x2="266" y2="104.5"/>
                <line id="XMLID_124_" className="st0" x1="266" y1="104.5" x2="271" y2="113.1"/>
                <line id="XMLID_125_" className="st0" x1="271" y1="113.1" x2="248.2" y2="207"/>
                <line id="XMLID_126_" className="st0" x1="248.2" y1="207" x2="232.9" y2="234.5"/>
                <line id="XMLID_127_" className="st0" x1="232.9" y1="234.5" x2="188.6" y2="272.9"/>
                <line id="XMLID_128_" className="st0" x1="188.6" y1="272.9" x2="166.7" y2="278.5"/>
                <line id="XMLID_129_" className="st0" x1="166.7" y1="278.5" x2="102" y2="216.2"/>
                <line id="XMLID_130_" className="st0" x1="102" y1="216.2" x2="84.5" y2="152.1"/>
                <line id="XMLID_131_" className="st0" x1="84.5" y1="152.1" x2="81" y2="110.4"/>
                <line id="XMLID_132_" className="st0" x1="81" y1="110.4" x2="164" y2="81.5"/>
                <line id="XMLID_133_" className="st0" x1="271" y1="113.1" x2="208.9" y2="106.3"/>
                <line id="XMLID_250_" className="st0" x1="208.9" y1="106.3" x2="148.1" y2="166"/>
                <line id="XMLID_251_" className="st0" x1="148.1" y1="166" x2="184.4" y2="242.2"/>
                <line id="XMLID_252_" className="st0" x1="184.4" y1="242.2" x2="248.2" y2="207"/>
                <line id="XMLID_253_" className="st0" x1="184.4" y1="242.2" x2="166.7" y2="278.5"/>
                <line id="XMLID_254_" className="st0" x1="148.1" y1="166" x2="84.5" y2="152.1"/>
                <line id="XMLID_255_" className="st0" x1="208.9" y1="106.3" x2="164" y2="81.5"/>
              </g>
            </g>
          </svg>
        </a>
      </div>
      <header className="App-header js-appHeader">
        {/* <div className="brackets">
          <img src={brackets}/>
        </div>
        <p>Looking for QR-code...</p> */}
      </header>

    </div>
  );
}

export default App;
