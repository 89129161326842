import {
  Color,
  AmbientLight,
  DirectionalLight,
  DirectionalLightHelper,
  HemisphereLight,
  HemisphereLightHelper,
  PointLight,
  PointLightHelper,
  RectAreaLight,
  SpotLight,
  SpotLightHelper
} from 'three'
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper'
import Debugger from '../../util/Debugger'
const GUI = Debugger

export default class LightController {
  constructor(options) {
      this._lights = options.lights || []
      this._scene = options.scene
      this._onUpdate = options.onUpdate || function() {}

      this.lights = []

      const n = Math.floor(Math.random() * 11)
      const k = Math.floor(Math.random() * 1000000)
      const m = String.fromCharCode(n) + k
      this._folderName = `Lights-${m}`
      this._folder = GUI.gui.addFolder(this._folderName)

      GUI.params.lights = {
          addAmbient: () => {
              this.addLight({ type: 'ambient', color: 0xffffff, intensity: 1, helper: false })
          },
          addDirectional: () => {
              this.addLight({ type: 'directional', color: 0xffffff, intensity: 1, castShadow: false, helper: false })
          },
          addHemisphere: () => {
              this.addLight({ type: 'hemisphere', skyColor: 0xffffff, groundColor: 0xd00000, intensity: 1, helper: false })
          },
          addPoint: () => {
              this.addLight({ type: 'point', color: 0xffffff, intensity: 1, distance: 10, decay: 2, castShadow: false })
          },
          addRect: () => {
              this.addLight({ type: 'rect', color: 0xffffff, intensity: 1, width: 10, height: 10 })
          },
          addSpot: () => {
              this.addLight({ type: 'spot', color: 0xffffff, castShadow: false, intensity: 1, distance: 10, angle: 0.79, penumbra: 0.2, decay: 2 })
          }
      }

      this._folder.add(GUI.params.lights, 'addAmbient')
      this._folder.add(GUI.params.lights, 'addDirectional')
      this._folder.add(GUI.params.lights, 'addHemisphere')
      this._folder.add(GUI.params.lights, 'addPoint')
      this._folder.add(GUI.params.lights, 'addRect')
      this._folder.add(GUI.params.lights, 'addSpot')

      this._setupLights()
  }

  _setupLights() {
      for (let i = 0; i < this._lights.length; i++) {
          const light = this._lights[i]
          this._checkLight(light, i)
      }
  }

  _checkLight(light, i) {
      switch (light.type) {
          case 'ambient': {
              const color = light.color ? light.color : new Color(0xffffff)
              const intensity = light.intensity >= 0 ? light.intensity : 1
              const castShadow = light.castShadow ? light.castShadow : false
              const id = light.id ? light.id : `ambient-light-${i}`
              this._lights[i].id = id

              const ambientFolder = this._folder.addFolder(id)

              const ambientLight = new AmbientLight(color, intensity)
              ambientLight.castShadow = castShadow

              GUI.params.lights.id = {}
              GUI.params.lights.id.intensity = intensity
              GUI.params.lights.id.color = color
              GUI.params.lights.id.castShadow = castShadow
              GUI.params.lights.id.remove = () => {
                  this._removeLight(id, ambientLight)
                  this._onUpdate()
              }

              ambientFolder.add(GUI.params.lights.id, 'intensity', 0, 10).onChange((value) => {
                  ambientLight.intensity = value
                  this._onUpdate()
              })
              ambientFolder.addColor(GUI.params.lights.id, 'color').onChange((value) => {
                  ambientLight.color = new Color(value)
                  this._onUpdate()
              })
              ambientFolder.add(GUI.params.lights.id, 'castShadow').onChange((value) => {
                  ambientLight.castShadow = value
                  this._onUpdate()
              })

              ambientFolder.add(GUI.params.lights.id, 'remove')

              this._scene.add(ambientLight)
              this.lights.push({ id, light: ambientLight })

              return ambientLight
          }
          case 'directional': {
              const color = light.color ? light.color : new Color(0xffffff)
              const x = light.x ? light.x : 0
              const y = light.y ? light.y : 0
              const z = light.z ? light.z : 0
              const intensity = light.intensity >= 0 ? light.intensity : 1
              const castShadow = light.castShadow ? light.castShadow : false
              const id = light.id ? light.id : `directional-light-${i}`
              this._lights[i].id = id

              const directionalFolder = this._folder.addFolder(id)

              const directionalLight = new DirectionalLight(color, intensity)
              directionalLight.position.x = x
              directionalLight.position.y = y
              directionalLight.position.z = z
              directionalLight.castShadow = castShadow

              const helper = new DirectionalLightHelper(directionalLight, 0.1)

              GUI.params.lights.id = {}
              GUI.params.lights.id.intensity = intensity
              GUI.params.lights.id.color = color
              GUI.params.lights.id.castShadow = castShadow
              GUI.params.lights.id.helper = false
              GUI.params.lights.id.x = directionalLight.position.x
              GUI.params.lights.id.y = directionalLight.position.y
              GUI.params.lights.id.z = directionalLight.position.z
              GUI.params.lights.id.remove = () => {
                  this._removeLight(id, directionalLight, helper)
                  this._onUpdate()
              }

              directionalFolder.add(GUI.params.lights.id, 'intensity', 0, 10).onChange((value) => {
                  directionalLight.intensity = value
                  this._onUpdate()
              })
              directionalFolder.addColor(GUI.params.lights.id, 'color').onChange((value) => {
                  directionalLight.color = new Color(value)
                  this._onUpdate()
              })
              directionalFolder.add(GUI.params.lights.id, 'castShadow').onChange((value) => {
                  directionalLight.castShadow = value
                  this._onUpdate()
              })
              directionalFolder.add(GUI.params.lights.id, 'x', -4, 4).step(0.005).onChange((value) => {
                  directionalLight.position.x = value
                  helper.update()
                  this._onUpdate()
              })
              directionalFolder.add(GUI.params.lights.id, 'y', -4, 4).step(0.005).onChange((value) => {
                  directionalLight.position.y = value
                  helper.update()
                  this._onUpdate()
              })
              directionalFolder.add(GUI.params.lights.id, 'z', -4, 4).step(0.005).onChange((value) => {
                  directionalLight.position.z = value
                  helper.update()
                  this._onUpdate()
              })
              directionalFolder.add(GUI.params.lights.id, 'helper').onChange((value) => {
                  if (value) {
                      this._scene.add(helper)
                  } else {
                      this._scene.remove(helper)
                  }

                  helper.update()
                  this._onUpdate()
              })

              directionalFolder.add(GUI.params.lights.id, 'remove')

              this._scene.add(directionalLight)
              this.lights.push({ id, light: directionalLight })

              return directionalLight
          }
          case 'hemisphere': {
              const skyColor = light.skyColor ? light.skyColor : new Color(0xffffff)
              const groundColor = light.groundColor ? light.groundColor : new Color(0x000000)
              const intensity = light.intensity >= 0 ? light.intensity : 1
              const id = light.id ? light.id : `hemisphere-light-${i}`
              this._lights[i].id = id

              const hemisphereFolder = this._folder.addFolder(id)

              const hemisphereLight = new HemisphereLight(skyColor, groundColor, intensity)

              const helper = new HemisphereLightHelper(hemisphereLight, 0.1)

              GUI.params.lights.id = {}
              GUI.params.lights.id.intensity = intensity
              GUI.params.lights.id.skyColor = skyColor
              GUI.params.lights.id.groundColor = groundColor
              GUI.params.lights.id.helper = false
              GUI.params.lights.id.x = hemisphereLight.position.x
              GUI.params.lights.id.y = hemisphereLight.position.y
              GUI.params.lights.id.z = hemisphereLight.position.z
              GUI.params.lights.id.remove = () => {
                  this._removeLight(id, hemisphereLight, helper)
                  this._onUpdate()
              }

              hemisphereFolder.add(GUI.params.lights.id, 'intensity', 0, 10).onChange((value) => {
                  hemisphereLight.intensity = value
                  this._onUpdate()
              })
              hemisphereFolder.addColor(GUI.params.lights.id, 'skyColor').onChange((value) => {
                  hemisphereLight.skyColor = new Color(value)
                  this._onUpdate()
              })
              hemisphereFolder.addColor(GUI.params.lights.id, 'groundColor').onChange((value) => {
                  hemisphereLight.groundColor = new Color(value)
                  this._onUpdate()
              })
              hemisphereFolder.add(GUI.params.lights.id, 'x', -60, 60).step(0.005).onChange((value) => {
                  hemisphereLight.position.x = value
                  helper.update()
                  this._onUpdate()
              })
              hemisphereFolder.add(GUI.params.lights.id, 'y', -60, 60).step(0.005).onChange((value) => {
                  hemisphereLight.position.y = value
                  helper.update()
                  this._onUpdate()
              })
              hemisphereFolder.add(GUI.params.lights.id, 'z', -60, 60).step(0.005).onChange((value) => {
                  hemisphereLight.position.z = value
                  helper.update()
                  this._onUpdate()
              })
              hemisphereFolder.add(GUI.params.lights.id, 'helper').onChange((value) => {
                  if (value) {
                      this._scene.add(helper)
                  } else {
                      this._scene.remove(helper)
                  }

                  helper.update()
                  this._onUpdate()
              })

              hemisphereFolder.add(GUI.params.lights.id, 'remove')

              this._scene.add(hemisphereLight)
              this.lights.push({ id, light: hemisphereLight })

              return hemisphereLight
          }
          case 'point': {
              const color = light.color ? light.color : new Color(0xffffff)
              const intensity = light.intensity >= 0 ? light.intensity : 1
              const distance = light.distance ? light.distance : 0
              const x = light.x ? light.x : 0
              const y = light.y ? light.y : 0
              const z = light.z ? light.z : 0
              const decay = light.decay ? light.decay : 1
              const castShadow = light.castShadow ? light.castShadow : false
              const id = light.id ? light.id : `point-light-${i}`
              this._lights[i].id = id

              const pointFolder = this._folder.addFolder(id)

              const pointLight = new PointLight(color, intensity, distance, decay)
              pointLight.position.x = x
              pointLight.position.y = y
              pointLight.position.z = z
              pointLight.castShadow = castShadow
              const helper = new PointLightHelper(pointLight, 0.1)
              if (light.helper) {
                  this._scene.add(helper)
              }

              GUI.params.lights.id = {}
              GUI.params.lights.id.intensity = intensity
              GUI.params.lights.id.distance = distance
              GUI.params.lights.id.decay = decay
              GUI.params.lights.id.color = color
              GUI.params.lights.id.castShadow = castShadow
              GUI.params.lights.id.helper = light.helper ? light.helper : false
              GUI.params.lights.id.x = pointLight.position.x
              GUI.params.lights.id.y = pointLight.position.y
              GUI.params.lights.id.z = pointLight.position.z
              GUI.params.lights.id.remove = () => {
                  this._removeLight(id, pointLight, helper)
                  this._onUpdate()
              }

              pointFolder.add(GUI.params.lights.id, 'intensity', 0, 10).onChange((value) => {
                  pointLight.intensity = value
                  this._onUpdate()
              })
              pointFolder.addColor(GUI.params.lights.id, 'color').onChange((value) => {
                  pointLight.color = new Color(value)
                  this._onUpdate()
              })
              pointFolder.add(GUI.params.lights.id, 'distance', 0, 200).step(0.1).onChange((value) => {
                  pointLight.distance = value
                  this._onUpdate()
              })
              pointFolder.add(GUI.params.lights.id, 'decay', 1, 2).step(1).onChange((value) => {
                  pointLight.decay = value
                  this._onUpdate()
              })
              pointFolder.add(GUI.params.lights.id, 'castShadow').onChange((value) => {
                  pointLight.castShadow = value
                  this._onUpdate()
              })
              pointFolder.add(GUI.params.lights.id, 'x', -60, 60).step(0.005).onChange((value) => {
                  pointLight.position.x = value
                  helper.update()
                  this._onUpdate()
              })
              pointFolder.add(GUI.params.lights.id, 'y', -60, 60).step(0.005).onChange((value) => {
                  pointLight.position.y = value
                  helper.update()
                  this._onUpdate()
              })
              pointFolder.add(GUI.params.lights.id, 'z', -20, 30).step(0.005).onChange((value) => {
                  pointLight.position.z = value
                  helper.update()
                  this._onUpdate()
              })
              pointFolder.add(GUI.params.lights.id, 'helper').onChange((value) => {
                  if (value) {
                      this._scene.add(helper)
                  } else {
                      this._scene.remove(helper)
                  }

                  helper.update()
                  this._onUpdate()
              })

              pointFolder.add(GUI.params.lights.id, 'remove')

              this._scene.add(pointLight)
              this.lights.push({ id, light: pointLight })

              return pointLight
          }
          case 'rect': {
              const color = light.color ? light.color : new Color(0xffffff)
              const intensity = light.intensity >= 0 ? light.intensity : 1
              const width = light.width ? light.width : 1
              const height = light.height ? light.height : 1
              const id = light.id ? light.id : `rect-area-light-${i}`
              this._lights[i].id = id

              const rectFolder = this._folder.addFolder(id)

              const rectLight = new RectAreaLight(color, intensity, width, height)
              const helper = new RectAreaLightHelper(rectLight)

              GUI.params.lights.id = {}
              GUI.params.lights.id.intensity = intensity
              GUI.params.lights.id.color = color
              GUI.params.lights.id.width = width
              GUI.params.lights.id.height = height
              GUI.params.lights.id.helper = false
              GUI.params.lights.id.x = rectLight.position.x
              GUI.params.lights.id.y = rectLight.position.y
              GUI.params.lights.id.z = rectLight.position.z
              GUI.params.lights.id.remove = () => {
                  this._removeLight(id, rectLight, helper)
                  this._onUpdate()
              }

              rectFolder.add(GUI.params.lights.id, 'intensity', 0, 10).onChange((value) => {
                  rectLight.intensity = value
                  this._onUpdate()
              })
              rectFolder.addColor(GUI.params.lights.id, 'color').onChange((value) => {
                  rectLight.color = new Color(value)
                  helper.update()
                  this._onUpdate()
              })
              rectFolder.add(GUI.params.lights.id, 'width', 1, 10).onChange((value) => {
                  rectLight.width = value
                  helper.update()
                  this._onUpdate()
              })
              rectFolder.add(GUI.params.lights.id, 'height', 1, 10).onChange((value) => {
                  rectLight.height = value
                  helper.update()
                  this._onUpdate()
              })
              rectFolder.add(GUI.params.lights.id, 'x', -60, 60).step(0.005).onChange((value) => {
                  rectLight.position.x = value
                  helper.update()
                  this._onUpdate()
              })
              rectFolder.add(GUI.params.lights.id, 'y', -60, 60).step(0.005).onChange((value) => {
                  rectLight.position.y = value
                  helper.update()
                  this._onUpdate()
              })
              rectFolder.add(GUI.params.lights.id, 'z', -60, 60).step(0.005).onChange((value) => {
                  rectLight.position.z = value
                  helper.update()
                  this._onUpdate()
              })
              rectFolder.add(GUI.params.lights.id, 'helper').onChange((value) => {
                  if (value) {
                      this._scene.add(helper)
                  } else {
                      this._scene.remove(helper)
                  }

                  helper.update()
                  this._onUpdate()
              })

              rectFolder.add(GUI.params.lights.id, 'remove')

              this._scene.add(rectLight)
              this.lights.push({ id, light: rectLight })

              return rectLight
          }
          case 'spot': {
              const x = light.x ? light.x : 0
              const y = light.y ? light.y : 0
              const z = light.z ? light.z : 0
              const color = light.color ? light.color : new Color(0xffffff)
              const intensity = light.intensity >= 0 ? light.intensity : 1
              const distance = light.distance ? light.distance : 50
              const angle = light.angle ? light.angle : 0.8
              const penumbra = light.penumbra ? light.penumbra : 0.5
              const decay = light.decay ? light.decay : 1
              const castShadow = light.castShadow ? light.castShadow : false
              const id = light.id ? light.id : `spot-light-${i}`
              this._lights[i].id = id

              const spotFolder = this._folder.addFolder(id)

              const spotLight = new SpotLight(color, intensity, distance, angle, penumbra, decay)
              spotLight.position.x = x
              spotLight.position.y = y
              spotLight.position.z = z
              spotLight.castShadow = castShadow
              const helper = new SpotLightHelper(spotLight, 0.1)

              GUI.params.lights.id = {}
              GUI.params.lights.id.intensity = intensity
              GUI.params.lights.id.angle = angle
              GUI.params.lights.id.distance = distance
              GUI.params.lights.id.penumbra = penumbra
              GUI.params.lights.id.decay = decay
              GUI.params.lights.id.color = color
              GUI.params.lights.id.helper = false
              GUI.params.lights.id.x = spotLight.position.x
              GUI.params.lights.id.y = spotLight.position.y
              GUI.params.lights.id.z = spotLight.position.z
              GUI.params.lights.id.remove = () => {
                  this._removeLight(id, spotLight, helper)
                  this._onUpdate()
              }

              spotFolder.add(GUI.params.lights.id, 'intensity', 0, 10).onChange((value) => {
                  spotLight.intensity = value
                  this._onUpdate()
              })
              spotFolder.addColor(GUI.params.lights.id, 'color').onChange((value) => {
                  spotLight.color = new Color(value)
                  this._onUpdate()
              })
              spotFolder.add(GUI.params.lights.id, 'x', -4, 4).step(0.005).onChange((value) => {
                  spotLight.position.x = value
                  helper.update()
                  this._onUpdate()
              })
              spotFolder.add(GUI.params.lights.id, 'y', -4, 4).step(0.005).onChange((value) => {
                  spotLight.position.y = value
                  helper.update()
                  this._onUpdate()
              })
              spotFolder.add(GUI.params.lights.id, 'z', -4, 4).step(0.005).onChange((value) => {
                  spotLight.position.z = value
                  helper.update()
                  this._onUpdate()
              })
              spotFolder.add(GUI.params.lights.id, 'distance', 0, 200).step(0.001).onChange((value) => {
                  spotLight.distance = value
                  helper.update()
                  this._onUpdate()
              })
              spotFolder.add(GUI.params.lights.id, 'penumbra', 0, 1).step(0.001).onChange((value) => {
                  spotLight.penumbra = value
                  helper.update()
                  this._onUpdate()
              })
              spotFolder.add(GUI.params.lights.id, 'angle', 0, Math.PI * 2).step(0.001).onChange((value) => {
                  spotLight.angle = value
                  helper.update()
                  this._onUpdate()
              })
              spotFolder.add(GUI.params.lights.id, 'decay', 1, 2).step(1).onChange((value) => {
                  spotLight.decay = value
                  helper.update()
                  this._onUpdate()
              })
              spotFolder.add(GUI.params.lights.id, 'helper').onChange((value) => {
                  if (value) {
                      this._scene.add(helper)
                  } else {
                      this._scene.remove(helper)
                  }

                  helper.update()
                  this._onUpdate()
              })

              spotFolder.add(GUI.params.lights.id, 'remove')

              this._scene.add(spotLight)
              this.lights.push({ id, light: spotLight })

              return spotLight
          }
          default:
              break
      }
  }

  // State ----------------

  getLight(id) {
      for (let i = 0; i < this.lights.length; i++) {
          const light = this.lights[i]

          if (light.id === id) {
              return light.light
          }
      }
  }

  addLight(light) {
      this._lights.push(light)

      return this._checkLight(light, this._lights.length - 1)
  }

  addLights(lights) {
      this._lights = lights

      for (let i = 0; i < this._lights.length; i++) {
          const light = this._lights[i]
          this._checkLight(light, i)
      }
  }

  _removeLight(id, lightObj, helper) {
      for (let i = 0; i < this._lights.length; i++) {
          const light = this._lights[i]
          const lightId = light.id

          if (id === lightId) {
              GUI.gui.removeLightFolder(id, this._folderName)
              this._scene.remove(lightObj)

              if (helper) { this._scene.remove(helper) }
          }
      }
  }

  destroy() {
      GUI.gui.removeFolder(this._folder)

      for (let i = 0; i < this.lights.length; i++) {
          const light = this.lights[i];
          this._scene.remove(light)
      }
  }
}