const ressources = {
  images: [],
  textures: [
    { id: 'map', url: 'textures/Kristelpaint001.jpg', init: true },
    // { id: 'map', url: 'textures/Kristelpaintpluswhite003.jpg', init: true },
    // { id: 'map', url: 'textures/KristelBake001.png', init: true },
    // { id: 'mask', url: 'textures/KristelHalfMask001.jpg', init: true },
  ],
  sounds: [],
  videos: [],
  models: [
    // { id: 'kristel', url: 'model/Kristel.glb', init: true },
    { id: 'kristel', url: 'model/KristelForMathis003.glb', init: true },
  ],
  jsons: [],
  fontTextures: [],
  fonts: []
};

export default ressources;
