import { AnimationMixer, Euler, LoopOnce, LoopRepeat, Object3D, Vector3 } from "three"
import Global from "../../util/Global"
import Emitter from "../../util/Emitter"
import { ONASSETSLOADED } from "../../core/constants"
import { addPositionGUI } from "../../util/3d"
import Kristel from "./Kristel"
import Words from "./Words"

export default class KristelScene extends Object3D {
  constructor({ camera, scene, renderer } = {}) {
    super()

    this.camera = camera
    this.scene = scene
    this.renderer = renderer
    this.timeout = false

    // const position = Global.debugOnDesktop ? new Vector3(0, 0, 1) : new Vector3(0, 0, -0.5)
    const position = Global.debugOnDesktop ? new Vector3(0, -1, 0) : new Vector3(0, -0.85, 0)
    // const rotation = Global.debugOnDesktop ? new Euler(-Math.PI * 0.5, 0, 0) : new Euler(0, Math.PI * -0.5, Math.PI * -0.5)
    const rotation = Global.debugOnDesktop ? new Euler(0, 0, 0) : new Euler(0, Math.PI * 0, Math.PI * 0)
    const scale = 1

    this.config = {
      position: { value: position, min: -5, max: 5, step: 0.01 },
      rotation: { value: rotation, min: -Math.PI, max: Math.PI, step: 0.01 },
      scale: { value: scale, min: 0.1, max: 2, step: 0.1 }
    }
    window.DebugController.addConfig(this.config, 'kristel')

    this.visible = true
    this.objectVisible = false
    this.started = false
    this.loaded = false

    this.animations = []

    this.setup()

    this.addEvents()
  }

  setup() {
    this.words = new Words()
    this.add(this.words)
  }

  // States -----

  show({ delay = 0 } = {}) {
    if (!this.objectVisible) {
      this.kristel.show({ delay })
      this.start()

      this.objectVisible = true
    }
  }
  hide() {
    if (this.objectVisible) {
      this.objectVisible = false
    }
  }

  start() {
    if (!this.started) {
      this.words.showNext()
      this.kristel.play({ onComplete: () => {
        this.stop()
      }})

      this.started = true
    }
  }

  stop() {
    if (this.started) {
      this.timeout = setTimeout(() => {
        this.start()
      }, 2000)
      this.started = false
    }
  }

  // Events -----

  addEvents() {
    Emitter.on(ONASSETSLOADED,this.onAssetsLoaded.bind(this))
  }

  onAssetsLoaded() {
    this.kristel = new Kristel({ camera: this.camera, scene: this.scene, renderer: this.renderer })
    this.add(this.kristel)

    if (Global.debugOnDesktop) {
      this.show({ delay: 2 })
    }

    this.loaded = true
  }

  // Update -----

  preRender({ delta, time, scale } = {}) {
    this.words.preRender()

    this.updateLocal()
    this.updateAnimation(delta)
  }

  updateLocal() {
    this.position.x = this.config.position.value.x
    this.position.y = this.config.position.value.y
    this.position.z = this.config.position.value.z

    this.rotation.x = this.config.rotation.value.x
    this.rotation.y = this.config.rotation.value.y
    this.rotation.z = this.config.rotation.value.z

    this.scale.set(this.config.scale.value, this.config.scale.value, this.config.scale.value)
  }

  updateAnimation(delta) {
    if (this.kristel) this.kristel.preRender({ delta })
  }
}