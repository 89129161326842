import {DepthFormat, DepthTexture, HalfFloatType, UnsignedShortType, WebGLRenderTarget} from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
// import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import BigTriangle from '../../helpers/3d/BigTriangle'
import RenderPass from './RenderPass'
import Bloom from './Bloom'
import Global from "../../util/Global";
// import BlurPass from './BlurPass'
// import DepthPass from './DepthPass'
// import DepthMaterialPass from './DepthMaterialPass'
// import DOFPass from './DOFPass'
// import FXAAPass from './FXAAPass'
// import LayerPass from './LayerPass'
// import PersistencePass from './PersistencePass'
// import KaleidoscopePass from './KaleidoscopePass'
// import SSAAPass from './SSAAPass'
// import SMAAPass from './SMAA'
// import TexturePass from './TexturePass'
// import DitheringPass from './DitheringPass'

export default class Postprocessing {
  constructor({
    ssaaPass = false,
    smaaPass = false,
    fxaaPass = false,
    layerPass = false,
    depthPass = false,
    depthMaterialPass = false,
    bloomPass = false,
    persistencePass = false,
    blurPass = false,
    dofPass = false,
    kaleidoscopePass = false,
    texturePass = false,
    ditheringPass = false,
    renderTarget = false,
    camera,
    renderer,
    scene
    // renderTarget = new WebGLRenderTarget(
    //   window.innerWidth * this.renderer.getPixelRatio(),
    //   window.innerHeight * this.renderer.getPixelRatio(),
    //   { samples: 2 }
    // )
  } = {}) {
    this.config = {
      active: { value: true },
      // samples: { value: renderTarget ? renderTarget.samples : 0, min: 0, max: 30, step: 1 },
      samples: { value: 0, min: 0, max: 30, step: 1 },
    }
    this.folder = window.DebugController.addConfig(this.config, 'Post Processing')

    this.camera = camera
    this.renderer = renderer
    this.scene = scene

    console.log(this.camera)
    console.log(this.renderer)
    console.log(this.scene)

    if (renderTarget) {
      if (window.Stage3d.isWebGL2Available) {
        this.composer = new EffectComposer(this.renderer, renderTarget)
      } else {
        console.warn('WebGL2 is not available')
      }
    } else {
      this.composer = new EffectComposer(this.renderer)
    }

    if (!depthMaterialPass) {
      // this.composer.writeBuffer.texture.type = HalfFloatType;
      // this.composer.readBuffer.texture.type = HalfFloatType;
      this.composer.writeBuffer.depthTexture = new DepthTexture()
      this.composer.writeBuffer.depthTexture.format = DepthFormat
      this.composer.writeBuffer.depthTexture.type = UnsignedShortType
      this.composer.readBuffer.depthTexture = new DepthTexture()
      this.composer.readBuffer.depthTexture.format = DepthFormat
      this.composer.readBuffer.depthTexture.type = UnsignedShortType
    }

    this.quad = new BigTriangle({
      positions: new Float32Array([-1, -1, 3, -1, -1, 3]),
      camera: true
    })

    this.renderPass = new RenderPass(this.scene, this.camera)
    this.composer.addPass(this.renderPass)
    
    // if (layerPass) this.layerPass = new LayerPass()
    // if (ssaaPass) this.ssaaPass = new SSAAPass()
    // if (smaaPass) this.smaaPass = new SMAAPass()
    // if (fxaaPass) this.fxaaPass = new FXAAPass()
    // if (depthPass) this.depthPass = new DepthPass()
    // if (depthMaterialPass) this.depthPass = new DepthMaterialPass()
    if (bloomPass) {
      this.bloomPass = new Bloom({ composer: this.composer, clear: !Global.ARActive })
      this.composer.addPass(this.bloomPass.bloomPass)
    }
    // if (persistencePass) this.persistencePass = new PersistencePass()
    // if (blurPass) this.blurPass = new BlurPass()
    // if (dofPass) this.DOFPass = new DOFPass()
    // if (kaleidoscopePass) this.kaleidoscopePass = new KaleidoscopePass()
    // if (texturePass) this.texturePass = new TexturePass()
    // if (ditheringPass) this.ditheringPass = new DitheringPass()

    // this.depthPass = new DepthPass()
    // this.dof = new Dof()

    // this.composer.setSize(window.innerWidth, window.innerHeight)

    this.addEvents()
  }

  addEvents() {
    // Emitter.on(Emitter.ONRESIZE, this.onResize.bind(this))
  }

  // States -----

  activate() {
    this.config.active.value = true
  }

  deactivate() {
    this.config.active.value = false
  }

  setCamera(camera) {
    console.log(this.composer)
  }

  // Getters -----

  getLayers() {
    return this.layerPass.layers
  }

  getLayer(id) {
    for (let i = 0; i < this.layerPass.layers.length; i++) {
      const layer = this.layerPass.layers[i];
      
      if (layer.id === id) {
        return layer
      }
    }
  }

  // Events -----

  onResize() {
    this.resize()
  }

  resize() {
    this.composer.setSize(window.innerWidth, window.innerHeight)
  }


  preRender() {
    if (this.bloomPass && this.bloomPass.config.active.value) this.bloomPass.preRender()
  }

  render({ renderTarget = null, scene = this.scene, camera = this.camera } = {}) {
    if (this.config.active.value) {
      this.renderer.autoClear = false;
      this.preRender()
      this.composer.render()
    } else {
      this.renderer.autoClear = !Global.ARActive;
      this.renderer.setRenderTarget(renderTarget)
      this.renderer.render(scene, camera)
    }
  }
}