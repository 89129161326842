// import Postprocessing from './Postprocessing'
// import {autobind} from "core-decorators";
// import {ONASSETSLOADED, ONMARKERFOUND, ONPLAYERREADY, ONSTART} from "../core/constants";
import {
  Mesh,
  BoxBufferGeometry,
  MeshBasicMaterial,
  DoubleSide,
  Object3D,
  Vector3,
  Euler
} from 'three'
import AssetLoader from '../core/AssetLoader'
import LightController from "../helpers/3d/LightController";
import DebugController from '../util/DebugController';
import Global from '../util/Global';
import KristelScene from "../webgl/KristelScene";

const WebARScene = () => {
    let cube
    let lightController
    let kristel
    let box
    let header
    let lookAt
    let config

    const initXrScene = (stage3D) => {
      const { scene, camera, content, renderer, canvas } = stage3D
      
      const rotation = Global.debugOnDesktop ? new Euler(0, 0, 0) : new Euler(0, Math.PI*0.5, 0)
      config = {
        rotation: { value: rotation, min: -Math.PI, max: Math.PI, step: 0.01 }
      }
      window.DebugController.addConfig(config, 'Box')

      header = document.body.querySelector('.js-appHeader')

      window.AssetLoader = new AssetLoader({
        gltf: true,
        ktx2: false,
        draco: false,
        exr: false,
        renderer
      })

      lightController = new LightController({
          lights: [
              { type: 'ambient', color: 0xffffff, intensity: 0.9, castShadow: false, helper: false },
              { type: 'point', color: 0xffffff, intensity: 2.47, distance: 30, decay: 2, x: 0, y: 19.465, z: -4 },
          ],
          scene
      })

      box = new Object3D()
      lookAt = new Vector3()

      kristel = new KristelScene({ camera, scene, renderer })
      box.visible = Global.debugOnDesktop
      box.add(kristel)
      scene.add(box)

      // cube = new Mesh(new BoxBufferGeometry(1,1,1), new MeshBasicMaterial({color: 'red', side:DoubleSide}))
      // cube.visible = true;
      // scene.add(cube);

      camera.position.set(0, 3, 0)
    }

    // Places content over image target
    const showTarget = ({ detail }) => {

        //AR MARKER
        // if (detail.name === 'coke_zs_325ml_flat' || detail.name === 'test-marker') {
        if (detail.name) {
            // cube.position.copy(detail.position)
            // cube.quaternion.copy(detail.rotation)
            // cube.scale.set(detail.scale, detail.scale, detail.scale)
            // if(!cube.visible){
            //     // alert('found')
            // }
            // cube.visible = true

            kristel.show()

            box.position.copy(detail.position)
            // box.quaternion.copy(detail.rotation)
            box.scale.set(detail.scale, detail.scale, detail.scale)
            box.visible = true

            header.style.display = 'none'

            // Emitter.emit("marker_found", detail.name);
        }
    }

    // May need to aply an offset
    const applyTracker = (target, detail) => {
        // target.position.copy(detail.position).addScaledVector(offsets.position, detail.scale)
        // target.quaternion.copy(detail.rotation);

        //Force the experience to ignore the market rotation and make it look at the user.
        // const temp_cam = new Vector3().copy(scope.camera.position);
        // temp_cam.y = target.position.y
        // target.lookAt(temp_cam)

        // target.scale.setScalar(detail.scale).multiply(offsets.scale);
    }

    // Hides the image frame when the target is no longer detected.
    const hideTarget = ({ detail }) => {
        // if (detail.name === 'coke_zs_325ml_flat' || detail.name === 'test-marker') {
        if (detail.name) {
            // cube.position.copy(detail.position)
            // cube.quaternion.copy(detail.rotation)
            // cube.scale.set(detail.scale, detail.scale, detail.scale)
            // if(cube.visible){
            //     // alert('lost')
            // }
            // cube.visible = false
            // Emitter.emit("marker_lost", detail.name);
            // scope.coke_tracker.visible = false

            // box.position.copy(detail.position)
            // box.quaternion.copy(detail.rotation)
            // box.scale.set(detail.scale, detail.scale, detail.scale)
            // box.visible = false

            //wolrd tracker stay visible after initialized for now.
            // scope.world_tracker.visible = false

            // header.style.display = 'block'
        }
    }

    // Grab a handle to the threejs scene and set the camera position on pipeline startup.
    const onStart = ({ canvas, GLctx }) => {
        console.log('onstart',window.XR8.Threejs.xrScene());
        const stage3D = window.XR8.Threejs.xrScene();
        const { scene, camera, renderer } = stage3D  // Get the 3js scene from XR8.Threejs

        //TODO: NOT HERE MOVE TO SCENE OR LOADER OR PIPELINE MODULE

        initXrScene(stage3D)  // Add content to the scene and set starting camera position.


        // prevent scroll/pinch gestures on canvas
        canvas.addEventListener('touchmove', (event) => {
            event.preventDefault()
        })

        // Sync the xr controller's 6DoF position and camera paremeters with our scene.
        window.XR8.XrController.updateCameraProjectionMatrix({
            origin: camera.position,
            facing: camera.quaternion,
        })
    }

    const onRender = () => {
      const stage3D = window.XR8.Threejs.xrScene()
      const { camera } = stage3D
      stage3D.preRender()

      kristel.preRender({ delta: stage3D.delta, time: stage3D.time })
      box.lookAt(camera.position)

      // box.rotation.x = 0

      // box.rotation.x += config.rotation.value.x
      // box.rotation.y += config.rotation.value.y
      // box.rotation.z += config.rotation.value.z
      // box.rotation.y = 0
      // box.rotation.z = 0


      stage3D.renderer.clearDepth();
      stage3D.render();
    }



    return {
        // Camera pipeline modules need a name. It can be whatever you want but must be
        // unique within your app.
        name: 'vans-ar-tracking',

        // onStart is called once when the camera feed begins. In this case, we need to wait for the
        // XR8.Threejs scene to be ready before we can access it to add content. It was created in
        // XR8.Threejs.pipelineModule()'s onStart method.
        onStart,
        onRender,

        onCanvasSizeChange: ({ canvasWidth, canvasHeight }) => {
            const stage3D = window.XR8.Threejs.xrScene();
            stage3D.resize(canvasWidth, canvasHeight);
            stage3D.post?.composer?.setSize(canvasWidth, canvasHeight);
        },

        // Listeners are called right after the processing stage that fired them. This guarantees that
        // updates can be applied at an appropriate synchronized point in the rendering cycle.
        listeners: [
            { event: 'reality.imagefound', process: showTarget },
            { event: 'reality.imageupdated', process: showTarget },
            { event: 'reality.imagelost', process: hideTarget },
        ],
    }
}

export default WebARScene;