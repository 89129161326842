/* eslint-disable no-undef */
import React, { useEffect, useRef } from "react"
import Global from "../util/Global";
import threejsPipelineModule from "./threejsPipelineModule";
import WebARScene from "./WebARScene";
import Scene3D from "./Stage3D";

const raf = require('raf')

//TODO: SETUP A HANDLE AND INITIALIZATION OF THE SCENE TO ENABLE 3D
const ARSceneComponent = React.memo(() => {

  const rafHandle = useRef();
  const canvasRef = useRef();
  const xrRef = useRef();

  useEffect(() => {
    console.log('hi');
    if (Global.ARActive) {
      const onxrloaded = () => {
        xrRef.current = window.XR8;
        window.XR8.XrController.configure({ disableWorldTracking: Global.debugOnDesktop })  // Disable default image targets.
        LandingPage.configure({
          mediaSrc: 'https://media.giphy.com/media/UIQc7mECaH5nw0Y03Y/giphy.mp4',
        })
        window.XR8.addCameraPipelineModules([  // Add camera pipeline modules.
          // Existing pipeline modules.
          XR8.GlTextureRenderer.pipelineModule(),      // Draws the camera feed.
          threejsPipelineModule(),                     // Custom Threejs renderer configuration
          XR8.XrController.pipelineModule(),           // Enables SLAM tracking.
          LandingPage.pipelineModule(),                // Detects unsupported browsers and gives hints.
          XRExtras.FullWindowCanvas.pipelineModule(),  // Modifies the canvas to fill the window.
          XRExtras.Loading.pipelineModule(),           // Manages the loading screen on startup.
          XRExtras.RuntimeError.pipelineModule(),      // Shows an error image on runtime error.
          WebARScene(),                                // actual 3D scene
          // Custom handlers for camera permission pipeline, permission, first render, permission denegated
        ])
        window.XR8.run({ canvas: canvasRef.current, verbose: true }) //use threejs pipeline canvas

      }
      const load = () => { XRExtras.Loading.showLoading({ onxrloaded }) }
      window.onload = () => { window.XRExtras ? load() : window.addEventListener('xrextrasloaded', load) }

    } else {
      // if not mobile then render a desktop version without AR and orbits controllers
      Scene3D.init({
        alpha: true,
        antialias: false,
        autoClear: true,
        clearColor: 0x000000,
        opacity: 1.0,
        preserveDrawingBuffer: true,
        webgl1: false,
        onResize: true,
        canvas: canvasRef.current,
      });

      Scene3D.renderer.setPixelRatio(window.devicePixelRatio || 1);

      rafHandle.current = raf(function tick() {
        // Animation logic
        Scene3D.render()
        raf(tick)
      })
    }

    // Cleanup
    return () => {
      raf.cancel(rafHandle.current)
      // on cleanup stop AR, not needed when starting component from begining.

      // if (Global.ARActive || window.XR8) {
      //   console.log('return',xrRef.current);
      //   xrRef.current.stop()
      //   xrRef.current.clearCameraPipelineModules()
      // }
    }
  }, [])

  console.log('rendering');
  return (
    <React.Fragment>
      <canvas ref={canvasRef} id="camerafeed"></canvas>
    </React.Fragment>
  )
})
export default ARSceneComponent;
