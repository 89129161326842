import { AnimationMixer, Color, LoopOnce, MeshBasicMaterial, Object3D } from "three"
import { gsap } from 'gsap'
import Global from "../../util/Global"
import Emitter from "../../util/Emitter"
import { ONASSETSLOADED } from "../../core/constants"
import { addStandardMaterial } from "../../util/3d"

export default class Kristel extends Object3D {
  constructor({ camera, scene, renderer } = {}) {
    super()

    this.camera = camera
    this.scene = scene
    this.renderer = renderer

    this.animations = []

    this.setup()

    this.addEvents()
  }

  setup() {
    const scene = window.AssetLoader.getModel('kristel').media.scene
    this.animations = window.AssetLoader.getModel('kristel').media.animations
    this.model = scene.children[0]

    this.mesh = this.model.children[1]
    const map = window.AssetLoader.getTexture('map').media
    map.flipY = false
    map.needsUpdate = true

    // const alphaMap = window.AssetLoader.getTexture('mask').media
    // alphaMap.flipY = false
    // alphaMap.needsUpdate = true

    this.mesh.frustumCulled = false
    /*this.mesh.material.transparent = true
    this.mesh.material.roughness = 0.12
    this.mesh.material.metalness = 0
    this.mesh.material.map = map*/
    // this.mesh.material.alphaMap = alphaMap
    // addStandardMaterial({ object: this.mesh })

    this.mesh.material = new MeshBasicMaterial({
      color: new Color('white'),
      map,
      // alphaMap,
      opacity: 0,
      transparent: true
    })

    this.mesh.material.needsUpdate = true

    this.animationMixer = new AnimationMixer(this.model)
    this.animationMixer.timeScale = 1
    this.clip = this.animations[0]
    this.action = this.animationMixer.clipAction(this.clip)
    this.action.clampWhenFinished = true
    this.action.loop = LoopOnce

    this.add(this.model)
  }

  // States -----

  show({ delay = 0 } = {}) {
    gsap.killTweensOf(this.mesh.material)
    gsap.fromTo(this.mesh.material, { opacity: 0 }, { opacity: 1, ease: 'Power2.easeOut', duration: 2, delay })
  }

  play({ onComplete = () => {} } = {}) {
    // this.action.stop()
    this.action.reset()
    // console.log('reset')
    // this.action.time = 0
    this.animationMixer.addEventListener('finished',()=>{
      onComplete()
    })
    this.action.play()
  }

  // Events -----

  addEvents() {}

  // Update -----

  preRender({ delta, time, scale } = {}) {
    this.updateAnimation(delta)
  }

  updateAnimation(delta) {
    if (this.animationMixer) {
      this.animationMixer.update(delta)
    }
  }
}