import { Vector2, Color } from 'three'
import {UnrealBloomPass} from "./UnrealBloomPass";

export default class Bloom {
  constructor({
    active = true,
    strength = 1.0,
    radius = 2,
    threshold = new Color(0.5, 0.65, 0.55),
    clear = true
  } = {}) {
    this.config = {
      active: { value: active },
      strength: { value: strength, min: 0, max: 20, step: 0.01 },
      radius: { value: radius, min: 0, max: 5, step: 0.01 },
    }

    this.bloomPass = new UnrealBloomPass(
      new Vector2(window.innerWidth, window.innerHeight),
      this.config.strength.value,
      this.config.radius.value,
        threshold
    )

    this.bloomPass.clear = clear
    this.bloomPass.enabled = this.config.active.value

    this.setupGUI()
  }

  setupGUI() {

    this.config.active.onChange = (value) => {
      this.bloomPass.enabled = value
    }

    this.config.strength.onChange = (value) => {
      this.bloomPass.strength = value
    }

    this.config.radius.onChange = (value) => {
      this.bloomPass.radius = value
    }

    // window.DebugController.addConfig(this.config, 'bloom', false, window.Stage3D.post.folder)
  }

  // States -----

  enable() {
    this.config.active.value = true
    this.enabled = this.config.active.value
  }

  removePass() {
    this.config.active.value = false
    window.Stage3d.post.composer.removePass(this.bloomPass)
  }

  // Update -----

  preRender() {
    this.bloomPass.enabled = this.config.active.value
    this.bloomPass.strength = this.config.strength.value
    this.bloomPass.radius = this.config.radius.value
  }
}