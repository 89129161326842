import { AnimationMixer, Color, Euler, LoopOnce, LoopRepeat, Object3D, Vector3 } from "three"
import {FontLoader} from "three/examples/jsm/loaders/FontLoader"
import Word from './Word'
import Emitter from "../../../util/Emitter"
import { addStandardMaterial } from "../../../util/3d"

export default class Words extends Object3D {
  constructor({ camera, scene, renderer } = {}) {
    super()

    this.items = [
      { text: 'Hello'},
      { text: 'Meet Futurekind'},
      { text: 'Future lab' },
      { text: 'Future research' },
      { text: 'Societal trends' },
      { text: 'Keynotes' },
      { text: 'Inspiration' },
      { text: 'Neo Culture' },
      { text: 'Experimental Labs' },
      { text: 'Future exhibitions' },
      { text: 'futurekind.be' },
    ]

    this.words = []
    this.index = 0

    const position = new Vector3(0, 1.3, 0.4)
    const rotation = new Euler(0, 0, 0)
    const metalness = 0.17
    const roughness = 0.31

    this.config = {
      position: { value: position, min: -5, max: 5, step: 0.01 },
      rotation: { value: rotation, min: -Math.PI, max: Math.PI, step: 0.01 },
      metalness: { value: metalness, min: 0, max: 1, step: 0.01 },
      roughness: { value: roughness, min: 0, max: 1, step: 0.01 },
      color: { value: new Color(0.9411764705882353, 0.3653402537485583, 0.7267271208686312).multiplyScalar(1.5) },
      color2: { value: new Color(0.3411764705882353, 0.9653402537485583, 0.7267271208686312).multiplyScalar(1.5) },
    }
    window.DebugController.addConfig(this.config, 'words')

    this.setup()

    this.addEvents()
  }

  setup() {
    this.loader = new FontLoader()
    // this.loader.load('fonts/Albert Sans_Bold.json', (font) => {
    this.loader.load('fonts/Questrial_Regular.json', (font) => {
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i]
        const word = new Word({ item, font })
        this.add(word)
        this.words.push(word)
      }
    })
  }

  // States -----

  showNext() {
    const nIndex = (this.index + 1) % this.words.length

    for (let i = 0; i < this.words.length; i++) {
      const word = this.words[i]

      if (i === nIndex) {
        word.show({ delay: 0.6 })
      } else {
        word.hide({ delay: 0})
      }
    }

    this.index = nIndex
  }

  // Events -----

  addEvents() {
    Emitter.on(Emitter.ONTOUCHEND, () => {
      if (this.index === this.words.length - 1) {
        window.open('https://www.futurekind.be/', '_blank')
      }
    })
  }

  // Update -----

  preRender({ delta, time, scale } = {}) {
    this.updateLocal()
    this.updateMaterials()
  }

  updateLocal() {
    this.position.x = this.config.position.value.x
    this.position.y = this.config.position.value.y
    this.position.z = this.config.position.value.z

    this.rotation.x = this.config.rotation.value.x
    this.rotation.y = this.config.rotation.value.y
    this.rotation.z = this.config.rotation.value.z
  }

  updateMaterials() {
    for (let i = 0; i < this.words.length; i++) {
      const word = this.words[i]
      const material = word.material
      material.color = i === this.words.length - 1 ? this.config.color2.value : this.config.color.value
      material.roughness = this.config.roughness.value
      material.metalness = this.config.metalness.value
    }
  }
}