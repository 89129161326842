import { AnimationMixer, Box3, Color, Euler, LoopOnce, LoopRepeat, Mesh, MeshStandardMaterial, Object3D } from "three"
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry"
import { gsap } from 'gsap'
import { addStandardMaterial } from "../../../util/3d"

export default class Word extends Object3D {
  constructor({ font, item } = {}) {
    super()

    this.geometry = new TextGeometry(item.text.toUpperCase(), {
      font,
      size: 0.2,
      height: 0.05
    })

    this.material = new MeshStandardMaterial({
      color: new Color(0.9411764705882353, 0.3653402537485583, 0.7267271208686312),
      opacity: 0,
      transparent: true
    })


    this.mesh = new Mesh(this.geometry, this.material)
    this.mesh.frustumCulled = false
    this.add(this.mesh)

    this.box3 = new Box3().setFromObject(this.mesh)
    this.mesh.position.x = Math.abs(this.box3.max.x - this.box3.min.x) * -0.5
    console.log(this.box3)

    this.visible = false
    this.objectVisible = false
  }

  // States -----

  show({ delay = 0 } = {}) {
    if (!this.objectVisible) {
      gsap.killTweensOf(this.position)
      gsap.fromTo(this.position, { x: 1 }, { x: 0, duration: 2, ease: 'Power4.easeOut', delay })

      gsap.killTweensOf(this.material)
      gsap.fromTo(this.material, { opacity: 0 }, { opacity: 1, duration: 2, ease: 'Power2.easeOut', delay })

      this.visible = true
      this.objectVisible = true
    }
  }

  hide({ delay = 0 } = {}) {
    if (this.objectVisible) {
      // gsap.killTweensOf(this.position)
      // gsap.to(this.position, { x: -1, duration: 2, ease: 'Power4.easeOut', delay })

      gsap.killTweensOf(this.material)
      gsap.to(this.material, { opacity: 0, duration: 0.5, ease: 'Power2.easeOut', delay, onComplete: () => {
        this.visible = false
      }})

      this.objectVisible = false
    }
  }

  // Events -----

  addEvents() {}

  // Update -----

  preRender({ delta, time, scale } = {}) {}
}