import Debugger from './Debugger'
const GUI = Debugger

class DebugController {
  constructor() {
    this.folders = []
    this.colors = []

    this.params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })

    document.body.querySelector('.dg.ac').style.zIndex = 999
  }

  queryDebug(name = 'dev') {
    const url = window.location.href
    name = name.replace(/[[]]/g, '\\$&')

    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
    const results = regex.exec(url)

    if (results) return decodeURIComponent(results[2].replace(/\+/g, ' ')) === 'true'

    return false
  }

  getQuery(name) {
    return this.params[name]
  }

  // addConfig({ folder = null, name = 'no-name', type = 'value', value = 0, min = 0, max = 20, step = 0.01, onChange = () => {} } = {}) {
  //   let directory = false
  //   let addFolder = true
    
  //   for (let i = 0; i < this.folders.length; i++) {
  //     const dossier = this.folders[i]

  //     if (dossier.name === folder) {
  //       directory = dossier
  //       addFolder = false
  //     }
  //   }

  //   if (!directory) directory = folder ? GUI.gui.addFolder(folder) : GUI.gui
  //   GUI.params[name] = value

  //   directory.add(GUI.params, name, min, max).step(step).onChange((value) => {
  //     onChange(value)
  //   })

  //   if (addFolder) this.folders.push(directory)
  // }

  addConfig(config, folder = 'no-name', opened = false, parent = false) {
    let directory = false
    let addFolder = true
    
    for (let i = 0; i < this.folders.length; i++) {
      const dossier = this.folders[i]

      if (dossier.name === folder) {
        directory = dossier
        addFolder = false
      }
    }

    if (!directory && !parent) directory = folder ? GUI.gui.addFolder(folder) : GUI.gui
    if (parent) directory = parent.addFolder(folder)

    for (const parameter in config) {
      if (config[parameter].value.isVector2 || config[parameter].value.isVector3 || config[parameter].value.isVector4  || config[parameter].value.isEuler) {
        
        let subFolder = config[parameter].value.isVector2 ? `${parameter} - Vec2` : `${parameter} - Vec3`
        subFolder = config[parameter].value.isVector4 ? `${parameter} - Vec4` : subFolder

        const newFolder = directory.addFolder(subFolder)
        if (config[parameter].opened) newFolder.open()

        newFolder.add(config[parameter].value, 'x', config[parameter].min, config[parameter].max).step(config[parameter].step).name(`${parameter}-x`).onChange((value) => {
          if (config[parameter].onChange) config[parameter].onChange(value)
        })

        newFolder.add(config[parameter].value, 'y', config[parameter].min, config[parameter].max).step(config[parameter].step).name(`${parameter}-y`).onChange((value) => {
          if (config[parameter].onChange) config[parameter].onChange(value)
        })

        if (config[parameter].value.isVector3 || config[parameter].value.isVector4 || config[parameter].value.isEuler) {
          newFolder.add(config[parameter].value, 'z', config[parameter].min, config[parameter].max).step(config[parameter].step).name(`${parameter}-z`).onChange((value) => {
            if (config[parameter].onChange) config[parameter].onChange(value)
          })
        }

        if (config[parameter].value.isVector4) {
          newFolder.add(config[parameter].value, 'w', config[parameter].min, config[parameter].max).step(config[parameter].step).name(`${parameter}-w`).onChange((value) => {
            if (config[parameter].onChange) config[parameter].onChange(value)
          })  
        }
      } else {
        if (typeof config[parameter].value === 'boolean') {
          directory.add(config[parameter], 'value').name(parameter).onChange((value) => {
            if (config[parameter].onChange) config[parameter].onChange(value)
          })
        } else if (config[parameter].value.isColor) {
          const color = config[parameter].value
          color._id = this.colors.length
          config[parameter].value.multiplyScalar(255)
          const colorController = directory.addColor(config[parameter], 'value').name(parameter).onChange((value) => {
            const id = colorController._id
            this.colors[id].r = value.r / 255
            this.colors[id].g = value.g / 255
            this.colors[id].b = value.b / 255
            console.log(this.colors[id])

            if (config[parameter].onChange) config[parameter].onChange(this.colors[id])

            setTimeout(() => { config[parameter].value = this.colors[id] })
          })
          colorController._id = this.colors.length
          this.colors.push(color)

          const id = colorController._id
          this.colors[id].r = config[parameter].value.r / 255
          this.colors[id].g = config[parameter].value.g / 255
          this.colors[id].b = config[parameter].value.b / 255
          config[parameter].value = this.colors[id]
        } else if (typeof config[parameter].value === 'function') {
          directory.add(config[parameter], 'value').name(parameter).onChange((value) => {
            if (config[parameter].onChange) config[parameter].onChange(value)
          })
        } else {
          directory.add(config[parameter], 'value', config[parameter].min, config[parameter].max).step(config[parameter].step).name(parameter).onChange((value) => {
            if (config[parameter].onChange) config[parameter].onChange(value)
          })
        }
      }
    }

    if (addFolder) {
      if (opened) directory.open()
      this.folders.push(directory)
    }

    return directory
  }
}

window.DebugController = new DebugController()
export default window.DebugController