import {
  Object3D,
  RawShaderMaterial,
  BufferGeometry,
  BufferAttribute,
  Mesh,
  OrthographicCamera } from 'three'
import Scene3D from "../../../8thwall/Stage3D";
import vertexShader from './shaders/big-triangle.vs'
import fragmentShader from './shaders/big-triangle.fs'

export default class BigTriangle extends Object3D {
  constructor({
    positions = new Float32Array([-0.5, -0.5, 1.5, -0.5, -0.5, 1.5]),
    uvs = new Float32Array([0, 0, 2, 0, 0, 2]),
    stageID = `big-triangle-${Math.random()}`,
    material = new RawShaderMaterial({
      uniforms: {
        t_diffuse: null,
      },
      vertexShader,
      fragmentShader,
    }),
    texture = null,
    scene = false,
    camera = false,
  } = {}) {
    super()

    this._geometry = new BufferGeometry()
    this._geometry.setAttribute('position', new BufferAttribute(positions, 2))
    this._geometry.setAttribute('uv', new BufferAttribute(uvs, 2))

    if (texture) {
      material.uniforms.t_diffuse = { value: texture }
      material.uniforms.t_diffuse.value.needsUpdate = true
    }

    this._material = material

    this._mesh = new Mesh(this._geometry, this._material)
    this._mesh.frustumCulled = false
    this.add(this._mesh)

    // console.log(Scene3D)

    if (camera) {
      this._camera = new OrthographicCamera(
        1 / -2,
        1 / 2,
        1 / 2,
        1 / -2,
        0,
        1
      );
    }
  }

  // States ------

  dispose() {
    this._geometry.dispose()
    this._material.dispose()
  }

  // Getters / Setters -----

  get geometry() {
    return this._geometry
  }

  get material() {
    return this._material
  }

  set material(material) {
    this._material = material
    this._mesh.material = material
  }

  get scene() {
    return this._scene
  }

  get mesh() {
    return this._mesh
  }

  get camera() {
    return this._camera
  }
}